.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &-danger {
    color: $persian_plum;
    background-color: $cherub;
    border-color: $azalea;
  }

  &-success {
    color: #06685c;
    background-color: #b1f3e9;
    border-color: $tradewind;
  }
}

.error-msg,
.success-msg,
.info-msg {
  position: absolute;
  left: 6px;
  font-weight: 300;
  font-size: 12px;
  font-weight: 500;
}

.error-msg {
  color: $carnation;
}

.success-msg {
  color: $surfie_green;
}

// field error
.errors-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;

  p {
    color: $monza;
    letter-spacing: 0.03em;
    margin-left: 2rem;
  }
}
