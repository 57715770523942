$font-path: '../../public/fonts';

@font-face {
  font-family: 'TT Norms';
  src: url('#{$font-path}/TTNorms-Medium.eot');
  src: url('#{$font-path}/TTNorms-Medium.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/TTNorms-Medium.svg#TTNorms-Medium') format('svg'),
    url('#{$font-path}/TTNorms-Medium.ttf') format('truetype'),
    url('#{$font-path}/TTNorms-Medium.woff') format('woff'),
    url('#{$font-path}/TTNorms-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TTNorms-Bold';
  src: url('#{$font-path}/TTNorms-Bold.eot');
  src: url('#{$font-path}/TTNorms-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/TTNorms-Bold.svg#TTNorms-Bold') format('svg'),
    url('#{$font-path}/TTNorms-Bold.ttf') format('truetype'),
    url('#{$font-path}/TTNorms-Bold.woff') format('woff'),
    url('#{$font-path}/TTNorms-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TTNorms-Regular';
  src: url('#{$font-path}/TTNorms-Regular.eot');
  src: url('#{$font-path}/TTNorms-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/TTNorms-Regular.svg#TTNorms-Regular') format('svg'),
    url('#{$font-path}/TTNorms-Regular.ttf') format('truetype'),
    url('#{$font-path}/TTNorms-Regular.woff') format('woff'),
    url('#{$font-path}/TTNorms-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'StoneSerifStd-Semibold';
  src: url('#{$font-path}/StoneSerifStd-Semibold.eot');
  src: url('#{$font-path}/StoneSerifStd-Semibold.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/StoneSerifStd-Semibold.svg#StoneSerifStd-Semibold') format('svg'),
    url('#{$font-path}/StoneSerifStd-Semibold.ttf') format('truetype'),
    url('#{$font-path}/StoneSerifStd-Semibold.woff') format('woff'),
    url('#{$font-path}/StoneSerifStd-Semibold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'StoneSerifStd-Medium';
  src: url('#{$font-path}/StoneSerifStd-Medium.eot');
  src: url('#{$font-path}/StoneSerifStd-Medium.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/StoneSerifStd-Medium.svg#StoneSerifStd-Medium') format('svg'),
    url('#{$font-path}/StoneSerifStd-Medium.ttf') format('truetype'),
    url('#{$font-path}/StoneSerifStd-Medium.woff') format('woff'),
    url('#{$font-path}/StoneSerifStd-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
