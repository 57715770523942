@import '@styles/variables';

.alert-overlay {
  position: fixed;
  inset: 0 0 0 0;
  z-index: $zindex-modal;

  background: rgba(29, 29, 27, 0.8);
}
.alert-modal-dialog {
  width: 310px;
  position: fixed;
  left: 50%;
  top: 230px;
  z-index: $zindex-modal;
  transform: translateX(-12%);
  .alert-modal {
    height: 154px;
    background-color: #e0e5e7;
    .alert-modal-header {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 50px;
      margin-bottom: 2px;
      padding: 0;

      letter-spacing: 0.03em;
    }
  }
  .alert-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: $river_bed1;
    letter-spacing: 0.03px;
    line-height: 15px;
    padding: 0px 16px;
    text-align: center;
    margin-bottom: 2px;
    background-color: $white;
  }
  .btn-ctr {
    background-color: $white;
    .btn-modal {
      width: 154px;
      height: 50px;
      background-color: $cerulean;
      color: $white;
      letter-spacing: 0.01em;
      &:last-of-type {
        margin-left: 2px;
      }
      &:only-child {
        margin: 0;
      }
      &:hover {
        background-color: $denim;
      }
    }
    .btn-fluid {
      width: 100%;
    }
  }
}
.center-alert {
  transform: translateX(-50%);
}
