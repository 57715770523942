.toggle-switch-wrap {
  display: flex;
  align-items: center;
  span {
    letter-spacing: 0.02em;
  }
  .tgl {
    display: none;
  }
  .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 44px;
    height: 22px;
    position: relative;
    cursor: pointer;
    user-select: none;
    background: $geyser;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    margin-bottom: 0;
    margin: 0 10px;
  }
  .tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: $white;
    transition: all 0.2s ease;
  }
  .tgl-light:checked + .tgl-btn {
    background: $tradewind;
  }
  .tgl:checked + .tgl-btn:after {
    right: 2px;
  }
  .tgl + .tgl-btn:after {
    position: absolute;
    display: block;
    content: '';
    width: 18px;
    height: 18px;
  }
}
