.color-picker-item {
  width: 22px;
  height: 22px;
  margin-right: 6px;
  margin-bottom: 6px;
  transform: scale(1);
  transition: transform 100ms ease 0s;
  &:nth-child(18),
  &:nth-child(36) {
    margin-right: 0;
  }
  &:nth-child(17),
  &:nth-child(35),
  &:nth-child(53),
  &:nth-child(70),
  &:nth-child(87) {
    visibility: hidden;
    opacity: 0;
    .color-picker-circle {
      visibility: hidden;
      opacity: 0;
    }
  }
  &:hover {
    .color-picker-circle {
      transform: scale(1.28);
    }
  }
  &.black-border {
    .color-picker-circle {
      border: 0.52px solid #000000;
      &.active {
        transform: scale(1.28);
        &::before {
          filter: brightness(0);
        }
      }
    }
  }
  .color-picker-circle {
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: relative;
    outline: none;
    border-radius: 50%;
    transition: background 100ms ease 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      transform: scale(1.28);
      &::before {
        content: '';
        background: url('/images/tick.svg') no-repeat;
        display: inline-block;
        width: 14px;
        height: 10px;
        transform: scale(0.8);
        background-size: cover;
      }
    }
  }
}
.custom-circle-picker {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: -6px;
  margin-bottom: -6px;
  position: relative;
}

.advanced-material-picker {
  display: inline-block;
  padding-left: 5px;
  max-width: 80px;
  overflow: hidden;
  vertical-align: middle;
  div {
    background: none !important;
    box-shadow: none !important;
  }
  .material-picker {
    padding: 0 !important;
    height: auto !important;
    .flexbox-fix {
      display: none !important;
    }
    > div {
      span {
        display: none !important;
      }
    }
    input {
      border: 0 !important;
      margin: 0 !important;
      background: none !important;
      height: auto !important;
      color: $river_bed1 !important;
      font-family: $font_nunitoSans;
      font-size: 14px !important;
    }

    label {
      display: none;
    }
  }
}
