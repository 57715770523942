@import '@styles/variables.scss';
@import '@styles/mixin.scss';

.input {
  position: relative;

  margin-bottom: 0.2rem;

  .title {
    @include formInputField;
  }

  .input-wrapper {
    flex-basis: 100%;
    display: flex;

    &:before {
      content: attr(data-prefix);
    }

    &:after {
      content: attr(data-suffix);
    }
  }

  input {
    width: 100%;
    height: 50px;

    padding: 1.6rem;

    font-size: 1.5rem;
    letter-spacing: 0.025em;
    color: $tradewind;
    text-align: center;

    &:disabled {
      background-color: $white;
    }
  }

  .upperCase {
    text-transform: uppercase;
    &::placeholder {
      text-transform: none;
    }
  }

  .visibility-btn {
    position: absolute;
    top: 12px;
    right: 15px;
  }
}
