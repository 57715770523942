.modal-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: $zindex-overlay;

  width: 100%;
  height: 100%;

  background-color: rgba($heavy_metal, 0.8);
}

.custom-modal {
  display: block;

  width: 550px;
  margin: 0 auto;

  background-color: $geyser;

  &-dialog {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: $zindex-modal;
  }

  &-header,
  &-footer {
    height: 50px;
    padding: 1.8rem;

    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: center;
    background: $porcelain;
  }
  &-header {
    .close {
      position: absolute;
      right: 18px;

      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.02em;
      color: $river_bed1;
    }
  }
  &-footer {
    margin-bottom: 0;
  }

  .color-picker-wrap {
    padding: 20px 25px 16px;
    background: $white;
  }
}
