.page-title {
  margin-top: 23px;
  margin-bottom: 122px;

  font-size: 26.82px;
  font-weight: 300;
  letter-spacing: 0.02em;
  text-align: center;
}

.no-data-message {
  font-style: italic;
  letter-spacing: 0.03em;
  margin-left: 20px;
  margin-top: 17px;
}

.form-label {
  background: $porcelain;

  & > span {
    display: inline-block;

    padding: 1.8rem;
    margin-bottom: 0;
    width: 100%;

    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.03em;
  }
}

.action-btn {
  width: 100%;
  height: 100%;

  color: $tradewind;
  letter-spacing: 0.01em;
  line-height: 15px;
  background-color: $porcelain;

  span {
    margin-left: 1.2rem;
  }
}

/* Start form input */
.form-field-wrapper {
  position: relative;
  margin-bottom: 2px;

  .g-input-label {
    display: inline-block;
    padding: 17px 0;

    width: 100%;
    margin-bottom: 2px;

    letter-spacing: 0.03em;
    line-height: 16px;
    background: $porcelain;
    text-align: center;
  }
  .form-input {
    width: 100%;
    height: 50px;
    padding: 16px;

    font-size: 15px;
    letter-spacing: 0.025em;
    color: $tradewind;
    border: 0;
    text-align: center;
  }
  .pen {
    position: absolute;
    top: 52px;
    right: 0;
  }
  .pr-55 {
    padding-right: 55px;
  }
}

.form-label-2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    display: inline-block;

    width: 100%;

    text-align: center;
  }
}

/* end form input */

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sort-asc::after,
.sort-desc::after {
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  top: 13px;
  right: 2px;
  background-size: contain;
  pointer-events: none;
}
.sort-asc::after {
  background-image: url('/images/ascending.png');
}
.sort-desc::after {
  background-image: url('/images/descending.png');
}

.checkBox {
  width: 28px;
  height: 28px;
  background-image: url('/images/right_sign_icon@2x.png');
  background-position: center;
  background-size: 10px 8px;
  background-repeat: no-repeat;

  &-disable {
    background-color: $geyser;
  }
  &-enable-blue {
    background-color: $cerulean;
  }
  &-enable-green {
    background-color: $tradewind;
  }
}

.selected-tab {
  position: absolute;
  top: 0;
  right: 0;

  display: inline-block;

  width: 50px;
  height: 50px;

  background-color: $tradewind;
  background-image: url('/images/edit_pen.svg');
}
