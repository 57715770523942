.btn {
  font-size: 14px;
  letter-spacing: 0.05em;
  padding: 18px 10px;
  min-width: 154px;
  border-radius: 0;
  border: 0;
  line-height: 14px;
  cursor: pointer;
  color: $white;
  &-success {
    background-color: $tradewind;
    &:hover {
      background-color: $surfie_green;
      color: $white;
    }
  }
  &-success-blue {
    background-color: $cerulean;
    &:hover {
      background-color: $denim;
      color: $white;
    }
  }
  &-secondary {
    background-color: $loblolly;
    &:hover {
      background-color: $loblolly;
    }
    &:active {
      box-shadow: none;
      background-color: $loblolly;
    }
    &:disabled {
      background-color: $loblolly;
      border-color: $loblolly;
    }
  }
  &-previous {
    margin-right: 2px;
    cursor: pointer;
  }
  &-disabled {
    pointer-events: none;
    opacity: 1;
  }
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}
