@import '@styles/variables.scss';

.site-wrapper {
  display: flex;
  height: 100vh;
  min-width: 1280px;
  .content-wrapper {
    width: 100%;
    background: $geyser;
    overflow-y: auto;

    & > * {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
