.mdr {
  font-family: $font_ttNormsRegular;
  font-variant-ligatures: none;
}
.mdb {
  font-family: $font_ttNormsBold;
  font-variant-ligatures: none;
}
.mdm {
  font-family: $font_ttNorms;
  font-variant-ligatures: none;
}
.cdr {
  font-family: $font_stoneSerifRegular;
}
.cdb {
  font-family: $font_stoneSerifSemibold;
}

// Remove
.ttRegular {
  font-family: $font_ttNormsRegular;
  font-variant-ligatures: none;
}
.ttBold {
  font-family: $font_ttNormsBold;
  font-variant-ligatures: none;
}
.tt {
  font-family: $font_ttNorms;
  font-variant-ligatures: none;
}
.ssRegular {
  font-family: $font_stoneSerifRegular;
}
.ssBold {
  font-family: $font_stoneSerifSemibold;
}
