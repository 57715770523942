@import '@styles/variables.scss';

.link {
  display: inline-block;
  white-space: nowrap;
}

.btn-like {
  padding: 18px 10px;
  min-width: 154px;

  color: $white;
  letter-spacing: 0.05em;
}

.font-sm {
  font-size: 1.5rem;
  line-height: 2.2rem;
}

.line {
  text-decoration: underline;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.black {
  color: $black;
  &:hover {
    color: $black;
  }
}

.blue {
  color: $cerulean;
  &:hover {
    color: $endeavour;
  }
}

.blue-bg {
  background-color: $cerulean;
  &:hover {
    background-color: $endeavour;
    color: $white;
  }
}
