@import './variables';
@import './fonts';
@import './typography';
@import './NextProgressBar';
@import './mixin';
@import './alert';
@import './button';
@import './toggle';
@import './radio';
@import './select';
@import './modal';
@import './page';
@import './date-picker';
@import './color-theme';
@import './configmodal';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; //default font size set 62.5% === 10px === 1rem
}

body {
  position: relative;
  min-height: 100vh;
  font-family: $font_nunitoSans;
  font-size: 1.4rem; // Body font size 14px
  line-height: 1.5;
  font-weight: 400;
  color: $river_bed1;
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  img,
  svg {
    pointer-events: none;
  }
}

img {
  width: auto;
  vertical-align: middle;
}

input {
  border: none;
}

::placeholder {
  color: $cascade2 !important;
  letter-spacing: 0.025em;
  font-family: $font_nunitoSans;
  font-style: italic;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

img,
svg {
  vertical-align: middle;
}

a {
  color: $black;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: $black;
    text-decoration: none;
  }
}

label {
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: $tradewind;
}

:focus {
  outline: 1px dotted transparent;
}

/*
		Position
*/
.position-relative {
  position: relative;
}

/*
		Margin and Padding
*/
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-0 {
  margin-bottom: 0;
}
.mr-2 {
  margin-right: 2px;
}
.mb-2 {
  margin-bottom: 2px;
}
.mt-2 {
  margin-top: 2px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mt-50 {
  margin-top: 50px;
}
.pl-50 {
  padding-left: 50px;
}
.pr-50 {
  padding-right: 50px;
}

/*
		display
*/
.d-none {
  display: none;
}
.d-inline-b {
  display: inline-block;
}

/*
		Flex
*/
.d-flex {
  display: flex;
}
.flex-grow {
  & > * {
    flex: 1;
  }
}
.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-column-fluid {
  flex: 1 0 auto;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.flex-end {
  justify-content: flex-end;
}

/*
		Pointer
*/
.no-pointer {
  pointer-events: none;
}
.cursor-pointer {
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed;
  pointer-events: auto;
}

/*
		Color
*/
.bg-white {
  background-color: $white;
}
.bg-gray {
  background-color: $porcelain;
}
.bg-green {
  background-color: $tradewind !important;
}

/*
		Text
*/
.text-center {
  text-align: center;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

/*
		width and height
*/
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.h-100 {
  height: 100%;
}

/*
		visibility
*/
.hidden {
  width: 100%;
  height: 100%;

  visibility: hidden;
}

// STRIPE GLOBAL

.StripeElement {
  height: 50px;
  padding: 18px;
  background-color: $white;
}

// RECHARTS

.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  display: none;
}
