@import '@styles/variables';

.h-100 {
  height: 100vh;
}

.invalid-page-wrap {
  background-color: $geyser;

  .pt-150 {
    padding-top: 15rem;
  }

  .invalid-page-content {
    max-width: 500px;
    margin: 0 auto;

    text-align: center;

    .warning-symbol {
      width: 160px;
      height: 160px;
      margin: 0 auto;

      background-image: url(/images/warning.svg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: $white;
      border-radius: 50%;
    }

    h1 {
      margin-top: 3rem;
      margin-bottom: 2.5rem;

      font-size: 3rem;
    }

    p {
      margin-bottom: 2.5rem;

      font-size: 1.6rem;
      line-height: 19px;
      letter-spacing: 0.03em;
      font-weight: 300;
      color: $river_bed1;
    }
  }
}
