@import '@styles/variables';

.btn {
  padding: 14.5px 10px;
  min-width: 154px;

  color: $white;
  letter-spacing: 0.05em;

  &-success {
    background-color: $tradewind;
    &:hover {
      background-color: $surfie_green;
    }
  }

  &-success-blue {
    background-color: $cerulean;
    &:hover {
      background-color: $denim;
    }
  }

  &-secondary {
    background-color: $loblolly;
    &:hover {
      background-color: $loblolly;
    }
    &:active {
      box-shadow: none !important;
      background-color: $loblolly !important;
    }
    &:disabled {
      background-color: $loblolly;
      border-color: $loblolly;
    }
  }

  &-disabled {
    pointer-events: none;
    opacity: 1;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.m-right-2 {
  margin-right: 2px;
}
