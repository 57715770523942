.pieChart {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  color: #333;

  .chartTitle {
    color: #333;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
  }

  .chartNoData {
    color: #999;
    font-size: 16px;
    font-weight: bold;
  }

  .chartContainer {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    position: relative;

    canvas {
      max-width: 100%;
    }

    .cutoutText {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      span {
        font-size: 30px;
        font-weight: bold;
        margin: 0;
      }

      p {
        font-size: 12px;
        color: #aaa;
        margin: 0;
      }
    }
  }
}