#nprogress {
  position: relative;
  pointer-events: none;

  .bar {
    position: fixed;
    z-index: $zindex-loader-bar;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: $denim;
  }

  .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px $denim, 0 0 5px $denim;
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }

  .spinner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zindex-loader-bar;

    display: flex;
    justify-content: center;
    align-items: center;
    animation-delay: 1s;

    .item {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 7px;
      display: flex;
      justify-content: center;
      align-items: center;

      @keyframes scale {
        0% {
          transform: scale(1);
        }
        50%,
        75% {
          transform: scale(2.5);
        }
        78%,
        100% {
          opacity: 0;
        }
      }

      &:before {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $denim;
        opacity: 0.7;
        animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
        transition: 0.5s all ease;
        transform: scale(1);
      }

      &--1 {
        background-color: darken($denim, 5%);
        &:before {
          animation-delay: 200ms;
        }
      }

      &--2 {
        background-color: darken($denim, 10%);
        &:before {
          animation-delay: 400ms;
        }
      }

      &--3 {
        background-color: darken($denim, 15%);
        &:before {
          animation-delay: 600ms;
        }
      }

      &--4 {
        background-color: darken($denim, 20%);
        &:before {
          animation-delay: 800ms;
        }
      }

      &--5 {
        background-color: darken($denim, 25%);
        &:before {
          animation-delay: 1000ms;
        }
      }
    }
  }
}
