@import 'react-datepicker/dist/react-datepicker.css';

.DayPickerInput {
  width: 100%;
  outline: 0;

  .react-datepicker {
    height: 244px;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }

  .DayPickerInput-custom {
    display: flex;

    .form-input {
      width: calc(100% - 50px);
      pointer-events: none;
    }
  }

  input {
    height: 50px;
    padding: 10px;

    color: $tradewind;
    text-align: center;
    border: 0;
    width: 100%;
    line-height: 14px;
    letter-spacing: 0.03em;

    &:focus {
      color: $tradewind;
      box-shadow: none;
      outline: 0;
    }
  }
}

.DayPickerInput-OverlayWrapper {
  &::before {
    content: '';
    width: 17px;
    height: 17px;
    display: inline-block;
    background: $river_bed1;
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: rotate(45deg);
  }
}

.react-datepicker {
  font-family: $font_nunitoSans;
  background-color: $river_bed1;
  border-radius: 0;
  border: 0;

  &__day-name,
  &__day,
  &__time-name {
    font-size: 10px;
    padding: 7.5px 5.5px;
    color: $white;
    line-height: 9px;
    border-radius: 0;
    width: auto;
    margin: 0;
    min-width: 24px;
    &.react-datepicker__day--disabled {
      color: $cascade3;
      cursor: default;
      &:hover {
        background-color: none;
      }
    }
  }

  &__day--keyboard-selected,
  &__month-text--keyboard-selected {
    background-color: $tradewind;
  }

  &__day {
    &:hover {
      border-radius: 0;
      background-color: $tradewind;
    }
  }

  &__day--outside-month {
    color: $cascade3;
    &:hover {
      background-color: none;
    }
  }

  &__day-name {
    font-size: 6.68px;
    font-weight: bold;
    -webkit-clip-path: inset(0 10px 0 0);
    clip-path: inset(0 10px 0 0);
    letter-spacing: 4px;
    text-align: center;
    width: 24px;
    height: 24px;
    padding: 7.5px 0 7.5px 6.5px;
  }

  &__day--selected,
  &__day--in-selecting-range,
  &__day--in-range,
  &__day--range-start {
    background-color: $tradewind !important;
  }

  &__header {
    background-color: $river_bed1;
    border: 0;
    border-radius: 0;
    padding-top: 15px;
  }

  &__current-month,
  &-time__header {
    font-size: 12.85px;
    font-weight: 300;
    color: $white;
    line-height: 15px;
  }

  &__month {
    margin: 0;
    padding: 10px 15px 16px;
  }

  &__day-names {
    padding-top: 20px;
  }

  &__navigation {
    position: absolute;
    top: 18px;
    background: url('/images/right-arrow.svg');
    background-size: cover;
    width: 19.45px;
    height: 10.24px;
    border: 0;

    &-icon {
      &--previous::before,
      &--next::before {
        display: none;
      }
    }

    &--previous {
      left: 15px;
      right: auto;
      transform: rotate(-180deg);
    }
    &--next {
      right: 15px;
      left: auto;
    }
  }

  &-popper {
    &[data-placement^='bottom'] {
      margin-top: 1px;
      .react-datepicker__triangle {
        top: -6px;
      }
    }
    &[data-placement^='top'] {
      margin-bottom: 0;
      .react-datepicker__triangle {
        bottom: -6px;
      }
    }
  }

  &-popper {
    .react-datepicker__triangle {
      position: absolute;
      left: auto !important;
      right: 26px !important;
      transform: translate3d(0px, 0px, 0px) !important;
      margin: 0 auto !important;
      &::before {
        width: 17px !important;
        height: 17px !important;
        background: $river_bed1 !important;
        transform: rotate(45deg);
        border: 0 !important;
      }
      &::after {
        border-top-color: $river_bed1 !important;
      }
    }
  }

  &__day--highlighted,
  &__month-text--highlighted,
  &__quarter-text--highlighted {
    background-color: #c1c7cb;
    color: #313131 !important;
  }
}
