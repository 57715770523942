@mixin placeholderInput {
  color: #b5b5c3;
  font-size: 1.3rem;
}

@mixin disabledField {
  background-color: #e9ecef;
  opacity: 1;
  cursor: not-allowed;
}

@mixin formInputField {
  margin-bottom: 0.8rem;

  flex-basis: 100%;

  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: $black;
}

@mixin formErrorWrap {
  position: absolute;
  bottom: -26px;

  & > * {
    margin-bottom: 0.5rem;

    font-weight: 500;
    font-size: 1.2rem;
    line-height: 18px;

    color: $carnation;
  }
}
