.custom-radio-group {
  flex: 0 0 50%;
  width: 50%;
  text-align: center;
  [type='radio'] {
    &:checked,
    &:not(:checked) {
      display: none;
      + label {
        position: relative;

        display: block;

        margin-bottom: 0;
        padding: 15px 0;

        color: $river_bed1;
        letter-spacing: 0.02em;
        background: $white;
        line-height: 20px;
        cursor: pointer;
        &:hover {
          background: #f3efef;
        }
      }
    }
    &:checked {
      + label {
        background: $tradewind;
        color: $white;
        &:hover {
          background: $surfie_green;
        }
      }
    }
  }
}
