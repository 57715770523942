@import '@styles/variables';

.sidebar-wrap {
  position: relative;
  flex: 0 0 240px;
  padding-top: 120px;
  width: 240px;
  height: 100vh;
  display: inline-block;
  background: $river_bed1;
  color: $white;

  .campaign-reset-btn-wrap {
    position: absolute;
    top: 0px;
    width: 100%;
    background-color: $white;
    .logo {
      width: inherit;
      height: 110px;
      padding: 10px;
      object-fit: contain;
    }
  }
  .navigation-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    ul {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        border-left: 2px solid #576271;
        height: 100%;
        top: 0;
        left: 38px;
      }
      .nav-item {
        h3 {
          color: $cerulean;
          font-size: 14px;
          margin: 0;
          padding: 20px 0 15px 57px;
          letter-spacing: 0.01em;
          flex: 0 0 100%;
          line-height: 15px;
        }
        .nav-link {
          display: flex;
          padding: 0;
          color: $white;
          &.nav-link-active {
            .counter {
              border-right-color: $cerulean;
              z-index: 1;
              color: $cerulean;
            }
          }

          &.nav-link-disabled {
            pointer-events: none;
          }
          .counter {
            font-size: 20px;
            color: #576271;
            letter-spacing: 0.1em;
            padding: 11.5px 12px;
            border-right: 2px solid transparent;
            font-weight: 300;
            line-height: 17px;
            flex: 0 0 40px;
          }
          .nav-name {
            padding: 11px 15px 12px;
            letter-spacing: 0.01em;
            flex: 0 0 calc(100% - 40px);
            line-height: 17px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .logout-btn {
          width: 100%;
          text-align: left;
        }
      }
    }
    .list {
      &-1-s {
        height: 160px;
      }
      &-1-l {
        height: 360px;
      }
      &-2 {
        height: 80px;
      }
      &-3 {
        height: 200px;
        &::after {
          left: 62px;
          z-index: 2;
          border-left: 2px solid $cerulean;
        }
        .nav-item {
          .nav-link {
            &.nav-link-active {
              .counter {
                background: $river_bed2;
                color: $white;
                z-index: 3;
              }
            }
            .counter {
              flex: 0 0 46px;
              padding-left: 33px;
              padding-left: 15px;
              margin-left: 18px;
              z-index: 1;
              background: $river_bed1;
            }
            .nav-name {
              flex: 0 0 calc(100% - 64px);
            }
          }
        }
      }
    }
  }
}
