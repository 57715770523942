@import '@styles/variables';

.stamps-tab-wrap {
  ul {
    justify-content: space-between;
    li {
      width: calc(33.33% - 1px);
      flex: 0 0 calc(33.33% - 1px);
      text-align: center;
      letter-spacing: 0.02em;
      background: $porcelain;
      line-height: 15px;
      &.react-tabs__tab--selected {
        &:focus {
          outline: none;
        }
        & .stamp-top-box {
          & .selected-tab {
            background-color: $surfie_green;
          }
        }
      }
      .stamp-top-box {
        position: relative;
        text-align: left;
        padding: 9px 14px;
        color: $river_bed1;
        line-height: 32px;
        cursor: pointer;
      }
    }
  }
}
.stamp-tabs-commom-wrap {
  display: flex;
  justify-content: space-between;
  .stamp-icon-box {
    height: 138px;
    background: $selective_yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(33.33% - 1px);
    flex: 0 0 calc(33.33% - 1px);
    &.add-stamp-icon {
      .stamp-icon {
        img {
          filter: brightness(0);
        }
      }
    }
    &.add-stamp-color,
    &.add-uncollected-stamp {
      .stamp-icon {
        background: rgba($black, 0.3);
        img {
          filter: opacity(40%);
        }
      }
    }
    &.add-stamp-color {
      &.add-stamp-color-default {
        .stamp-icon {
          background: none;
        }
      }
      .stamp-icon {
        &.has-stamp-icon {
          background: none;
        }
      }
    }
    &.add-uncollected-stamp {
      .stamp-icon.has-stamp-icon {
        background: none;
        svg {
          max-width: 100px;
          height: 100px;
          width: 100px;
        }
      }
      &.add-uncollected-stamp-default {
        .stamp-icon {
          background: none;
          svg {
            max-width: 100px;
            height: 100px;
            width: 100px;
          }
        }
      }
    }
    .stamp-icon {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      &.has-stamp-icon {
        svg {
          max-width: 100px;
          width: 100px;
          height: 100px;
        }
      }
      img {
        max-width: 34px;
      }
    }
  }
}
.stamps-icon-content-wrap {
  .stamp-catg-list-bar {
    display: flex;

    .icon-color-type {
      background: $porcelain;
      padding: 0 11px;
      margin-left: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 15px;
    }
  }
}
.stamp-icons-list-wrap {
  padding-right: 2px;
  padding-left: 8px;
  ul {
    .stamp-icons-list-item {
      margin-bottom: 6px;
      display: inline-block;
      margin-right: 6px;
      cursor: pointer;
      svg,
      img {
        pointer-events: none;
        width: 48px;
        height: 48px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &.cursor {
        cursor: pointer;
      }
    }
    .active-stamp {
      margin-right: 2px;
      margin-bottom: 2px;
      margin-left: -4px;
      margin-top: -4px;
      padding: 2px;
      border-radius: 50%;
      border: 2px solid $tradewind;
    }
  }
}
.list-wrap-set-1 {
  padding-top: 14px;
  padding-bottom: 5px;
  margin-bottom: 2px;
  background: $white;
}
.list-wrap-set-2 {
  padding-top: 11px;
  padding-bottom: 11px;
  background: $white;
}
.stamp-color-content-wrap {
  .configurator-picker-wrap {
    padding: 20px 25px 16px 25px;
    background: $white;
    .custom-circle-picker {
      position: relative;
      .custom-picker {
        position: absolute;
        right: 24px;
      }
      .color-reset-btn {
        position: absolute;
        right: 2px;
        top: 56px;
        width: 22px;
        height: 22px;
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}
.color-edit-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    flex: 0 0 100%;
    width: 100%;
    background: $porcelain;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    .color-circle {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 10px;
      background: red;
      vertical-align: middle;
      margin-left: 15px;
    }
    span {
      display: inline-block;
    }
    .edit-color {
      cursor: pointer;
      color: $tradewind;
      font-style: italic;
    }
  }
}
