.container {
    color: #333;

    .rewardTable {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            padding: 10px;
            text-align: center;
            border-bottom: 1px solid #e5e5e5;
        }

        tr:last-child td {
            border-bottom: none; // Remove bottom border for the last row
            padding-bottom: 0px;
        }

        thead {
            background-color: #f8f9fa;
        }

        .performanceWrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                min-width: 40px;
            }
        }

        .pieChart {
            width: 35px;
            height: 35px;
            margin-left: 10px;
        }
    }

    .chartHeader {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .chartTitle {
            font-size: 16px;
            font-weight: 600;
            .chartSubTitle {
                font-size: 10px;
            }
        }
    }
}