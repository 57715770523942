.g-select {
  .pl {
    &--is-disabled {
      pointer-events: initial;
      &__value-container--has-value {
        cursor: not-allowed;
      }
    }

    &__control {
      height: 50px;
      margin-bottom: 2px;
      border: none;
      border-radius: 0px;
      background-color: $white;
      &:hover {
        border: none;
        cursor: pointer;
      }
      &__control--is-focused {
        box-shadow: none;
        &:hover {
          border: none;
        }
      }
      &__value-container--has-value {
        height: inherit;
      }
    }

    &__menu {
      margin-bottom: 0;
      margin-top: 2px;
      left: 0px;
      box-shadow: none;
      border-radius: 0px;
      &-list {
        padding: 0px;
        line-height: 52px;
        max-height: max-content;
      }
    }

    &__option {
      padding: 0 0 0 19px;
      letter-spacing: 0.03em;
      color: $cascade1;
      font-size: 14px;
      cursor: pointer;
      &--is-selected,
      &--is-focused {
        background-color: $white;
        color: $cascade1;
        padding: 0 0 0 19px;
      }
      .payment_provider {
        text-align: center;
        margin-left: -19px;
        img {
          height: 45px;
          &[alt='Valitor'] {
            height: 25px;
          }
        }
      }
    }

    &__dropdown-indicator {
      padding-right: 17px;
      svg {
        width: 16px;
        height: 8px;
      }
    }

    &__single-value,
    &__placeholder {
      margin-left: 11px;
      letter-spacing: 0.03em;
    }
    &__single-value {
      color: $tradewind;
      .payment_provider {
        img {
          height: 40px;
          &[alt='Valitor'] {
            height: 25px;
          }
        }
      }
    }
    &__placeholder {
      color: $cascade2;
      font-style: italic;
    }
  }
}

/*
*
* auth dropdown
*/

.auth-select {
  .pl {
    &__value-container {
      margin-left: 41px;
      justify-content: center;
    }
    &__dropdown-indicator {
      padding-right: 21px;
    }
    &__control--menu-is-open {
      &__dropdown-indicator {
        padding-left: 21px;
        padding-right: 4px;
        transform: rotate(180deg);
        transition: all 0.3s;
      }
    }
    &__menu-list {
      max-height: 200px !important;
      &__option {
        .payment_provider {
          margin-left: 0;
        }
      }
    }
    &__placeholder,
    &__single-value {
      margin-left: 0 !important;
      margin-right: 0;
    }
    & {
      &__option,
      &__option--is-focused,
      &__option--is-selected {
        padding: 0 16px !important;
      }
    }
  }
}

/*
*
* Manage campaign dropdown
*/

.camp-list-status {
  .pl {
    &__option {
      padding: 0;
      text-align: center;
      text-transform: capitalize;
    }
    &__single-value {
      text-transform: capitalize;
    }
    &__menu-list {
      max-height: 200px;
      height: auto;
    }
    &__placeholder {
      color: inherit;
      font-style: unset;
    }
  }
}

/*
*
* stamp configurator dropdown
*/

.catg-dropdown-wrap {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  .not-allowed {
    cursor: not-allowed;
  }
  .catg-arrow {
    width: 50px;
    height: 50px;
    background: $porcelain url('/images/right-arrow-black.svg') no-repeat center center/19.45px
      10.24px;
    &.arrow-left {
      transform: rotate(-180deg);
    }
  }
  .catg-dropdown {
    background: $porcelain;
    width: calc(100% - 104px);
    margin: 0 2px;
    .pl {
      &__menu-list {
        max-height: 259px;
      }
      &__value-container {
        justify-content: center;
      }
      &__option {
        padding: 0 17px 0 0;
      }
      &__option--is-selected {
        background-color: #5dbbad3b;
      }
      &__option--is-focused {
        color: $surfie_green;
      }
    }
  }
}

/*
*
* Stamp campaign setup
*
*/

.setup-dropdown {
  .pl {
    &__control {
      background-color: $tradewind;
      color: $white;
      &--menu-is-open,
      &:hover {
        background-color: $surfie_green;
      }
    }
    &__menu-list {
      max-height: 200px;
      height: auto;
    }
    &__placeholder,
    &__single-value {
      color: $white;
      letter-spacing: 0.02em;
      font-style: normal;
    }
    &__single-value,
    &__placeholder {
      margin: 0;
    }
    &__value-container {
      padding: 15px 22px;
    }
    &__dropdown-indicator {
      padding-right: 17px;
      & svg {
        width: 13px;
        height: 11px;
      }
    }
    &__option--is-focused {
      background-color: #efefef;
    }
  }
}

/*
*
* Review campaign TnC
*
*/

.tandc-select {
  .pl {
    &__placeholder {
      color: $river_bed1;
      letter-spacing: 0.02em;
      font-style: normal;
    }
    &__value-container {
      padding: 17px 7px;
    }
  }
}

.card-select {
  .pl {
    &__control {
      background-color: $tradewind;
    }
    &__placeholder,
    &__single-value {
      color: $white;
    }
    &__value-container {
      padding: 17px 7px;
    }
  }
}
